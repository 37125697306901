import { storeToRefs } from 'pinia';
import { useQueryPostsStore } from '../stores/queryPostsStore'
import axios from 'axios';
const BASE_API='https://api.consulting.itso.ec/'

const useQueryPosts = () => {
    const queryPostsStore = useQueryPostsStore();
    
    const {titleQuery,posts, selectedPost, error, loading, results, page,nextPage,prevPage, totalPages,prevFilters} = storeToRefs(queryPostsStore);

    const initializeAllPosts = async()=>{
        loading.value = true;
        queryPostsStore.loadPosts(await axios.get(BASE_API+'posts'))
        loading.value = false;
    };
    const initializeQueriedPosts = async(id)=>{
        loading.value = true;
        queryPostsStore.loadPosts(await axios.get(BASE_API+'posts?filter={"category":"'+id+'","status":"Aprobado II"}'))
        loading.value = false;
    };

    const initializeByQuery = async ({title, limit=10,customFilters}) => {
        loading.value = true;
        const filters = {
            "status":"Aprobado II"
        }
        if(customFilters != null && Object.keys(customFilters).length > 0) {
            
            Object.assign(filters, {...filters, ...customFilters})
        }

        if(title!= null && title.length>0) {
            Object.assign(filters,{...filters, "title": title})
        }

        prevFilters.value = {...filters};
        queryPostsStore.loadPosts(await axios.get(BASE_API+'posts',
        {
            params:{
                filter: filters,
                limit:limit,
            }
        }))
        loading.value = false;
    
    };
    const changePage = async ({page=1, limit=10}) => {
        



        queryPostsStore.loadPosts(await axios.get(BASE_API+'posts',
        {
            params:{
                filter:prevFilters.value,
                page:page,
                limit:limit,
            }
        }))
        loading.value = false;
    
    };

    const selectPostById = (id) => queryPostsStore.getPostById(id);

    return {
        // Properties
        titleQuery,
        posts,
        selectedPost,
        error,
        loading,
        results,
        page,
        nextPage,
        prevPage,
        totalPages,
        changePage,
        prevFilters,
        //methods
        initializeAllPosts,
        initializeByQuery,
        initializeQueriedPosts,
        selectPostById

    }
}
export default useQueryPosts;
