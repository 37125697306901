import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import axios from 'axios';

const BASE_API='https://api.consulting.itso.ec/'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'auth.login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'auth.register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterView.vue')
  },
  {
    path: '/reset-password',
    name: 'auth.resetpassword',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ProfileView.vue')
  },
  {
    path: '/search',
    name: 'search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue')
  },
  {
    path: '/listpage',
    name: 'list.page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PageList.vue')
  },
  {
    path: '/page/:pageId?',
    name: 'page',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PageView.vue')
  },
  
  {
    path: '/page/:pageId/category/:categoryId?',
    name: 'page.category',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoryView.vue')
  },
 
   
  {
    path: '/page/:pageId/category/:categoryId/post/:postId?',
    name: 'page.category.post',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PostView.vue')
  },
 
  {
    path: '/orders',
    name: 'orders',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OrdersView2.vue')
  },
 
  
]

let isAuthenticated = false;


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

router.beforeEach(async (to) => {
  
  let cookie = getCookie('auth_token')
  if(!cookie) isAuthenticated = false;

  if(to.name =='auth.register') {
    window.location.href='https://wa.me/593958938356'
  }
  //public routes
  if(to.name=='auth.login' || to.name=='home') {
    return ;
  }
  if (
    
    // make sure the user is authenticated
    !isAuthenticated ) {

    //Attempt to login from cookie
    if(cookie){
      let user =await axios.get(BASE_API+'auth/me',
      {
          headers: {"Authorization":`Bearer ${cookie}`}
          
      })
      if(user.status==200)
      {
        isAuthenticated = true;
        return;
      }
      else{
        isAuthenticated = false;
        return { name: 'auth.login' }
      }

    }
    // redirect the user to the login page
    return { name: 'auth.login' }
  }
})

export default router
