import { defineStore } from 'pinia';



export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user:{},
        error: '',
        loading: false,
        
    }),
    getters: {},
    actions: {
        getUserById(id) {
            this.selectedPost = this.posts?.find((post) => post.id == id);
            
        },
        loadUser(data) {
            try {
                this.user = data.data.data;
                
                } catch (error) {
                this.error = error;
                console.error(error);
           }
        },
     
    }
});

