<script setup>
import { ref,watch,defineProps, onMounted } from "vue";
import PrimaryButton from "@/components/ActionButton.vue"
import SecondaryButton from "@/components/SecondaryButton.vue"
import { getCurrentInstance } from "vue";
import axios from 'axios';
import { useRouter } from "vue-router";


import AppLogo from "@/components/AppLogo.vue";
import NavDropdown from "@/components/NavDropdown.vue"
import useQueryPosts from '@/composables/useQueryPosts';
import useAuth from "@/composables/useAuth";

const app = getCurrentInstance();
const primaryColor = app.appContext.config.globalProperties.primaryColor;
const secondaryColor = app.appContext.config.globalProperties.secondaryColor;
const blankColor = app.appContext.config.globalProperties.blankColor;
const router = useRouter();


const {user, getUserFromToken} = useAuth();
// eslint-disable-next-line no-unused-vars
const props = defineProps(['searchbar']);

const { posts, initializeByQuery, loading } = useQueryPosts();

const category = ref({});
const API = 'https://api.consulting.itso.ec/';
const dropdownOpen = ref(false);
const titleQuery = ref('');

const getCategoryById = async(id) => {
      await axios.get(API+'categories/'+id)
      .then((res)=> {
        category.value= res.data
        
        
      })
      .catch((err)=>{
        
      })
    }


const findLink = async (post) => {
  await getCategoryById(post.category);
  router.push(`/page/${category.value.view}/category/${post.category}/post/${post.id}`)

}



function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


watch(titleQuery, (currentQuery)=>{
  (currentQuery != '') ?  initializeByQuery({title: currentQuery, limit: 3}) : null; 
})

onMounted(()=>
{
  let cookie = getCookie("auth_token");
  if(cookie) {
    getUserFromToken(cookie);
  }
})
</script>

<template>
  <nav :class="(scrolled || /^profile*/.test($route.name)|| /^search*/.test($route.name)|| /^orders*/.test($route.name)|| /^list.page*/.test($route.name))? `bg-[${primaryColor}] shadow-lg` : `bg-none  border-gray-200 `" class="transition duration-300 px-4 lg:px-6 py-2.5">
            <div class="grid grid-cols-2 lg:grid-cols-4 items-center mx-auto max-w-screen-xl">
                <AppLogo/>
                <!-- Hamburguer/Login-Register -->
                <div class="flex items-center lg:order-2  justify-self-end">
                    <router-link v-if="!user" to="/login" :class="`text-[${blankColor}] hover:bg-opacity-20 hover:bg-[${secondaryColor}]`" 
                    class="hidden sm:block text-sm text-white focus:ring-4 focus:ring-gray-300 font-medium rounded-lg px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none">Login</router-link>
                
                
                    <router-link v-if="!user" to="/register">
                      <PrimaryButton
                      v-if="!scrolled" class="hidden sm:block text-sm focus:ring-2 focus:ring-blue-300 font-medium px-4 mr-2 focus:outline-none"
                      >Suscribirse</PrimaryButton>
                      <SecondaryButton
                      v-else  class="hidden sm:block text-sm focus:ring-2 focus:ring-blue-300 font-medium px-4 mr-2 focus:outline-none"
                      >Suscribirse</SecondaryButton>
                      
                    </router-link>
                  
                    <NavDropdown class=""/>

                </div>

                <!-- Search Input -->
                <div class="hidden   lg:flex lg:w-auto lg:order-1  col-span-2 " id="mobile-menu-2">
                  <div v-if="searchbar" class="relative form-input w-full items-center  lg:flex">
                    <div class="absolute left-2 top-0 bottom-0 flex items-center justify-center z-10">
                      <BaseIcon :size="'h-[20px] w-[20px]'" :class="`text-blue-500`" name="search"/>
                    </div>
          
                    <input id="searchquery" class="w-full outline-none ring-0 ml-6" type="text"  
                      v-model="titleQuery"  
                    @focusin="dropdownOpen = true"
                    @focusout="dropdownOpen = false" />
                    <transition
                      enter-active-class="transition ease-out duration-200 transform "
                      enter-from-class="opacity-0 -translate-y-2"
                      enter-to-class="opacity-100 translate-y-0"
                      leave-active-class="transition ease-out duration-200"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                    >
                      <div v-show="dropdownOpen" class="origin-top-right -ml-3 z-10 absolute top-full  min-w-36 w-full bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden px-2" >
                      
                        <ul v-if="loading">
                          <li class="flex justify-center items-center">
                            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-sky-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Cargando...
                          </li>
                        </ul>
                        <ul v-else-if="posts?.length >0" class="-mx-2 -my-1.5">
                          <li @click="findLink(post)" class="flex justify-between items-center cursor-pointer hover:bg-slate-100 hover:font-bold hover:text-sky-500  px-2 py-1.5" v-for="post in posts" :key="post.id">
                            
                            <span class="truncate pr-8 p-1">{{ post.title }}</span>
                            <span class="justify-self-end">
                              <svg class="h-4 w-4 text-sky-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                              </svg>
                            </span>
                          </li>
                          <li class="">
                            <router-link to="/search" class="flex justify-between items-center cursor-pointer hover:bg-slate-100 hover:font-bold px-2 py-1.5">
                            <span  class="truncate pr-8 p-1 hover:text-sky-500 inline-block w-full text-start">Ir a búsqueda avanzada</span>
                            <span class="justify-self-end">
                              <svg class="h-4 w-4 text-sky-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                              </svg>
                            </span>
                          </router-link>
                          </li>
                        </ul>
                        <ul v-else  class="-mx-2 -my-1.5">
                          <li class="">
                            <router-link to="/search" class="flex justify-between items-center cursor-pointer hover:bg-slate-100 hover:font-bold px-2 py-1.5">
                            <span  class="truncate pr-8 p-1 hover:text-sky-500 inline-block w-full text-start">Ir a búsqueda avanzada</span>
                            <span class="justify-self-end">
                              <svg class="h-4 w-4 text-sky-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/>
                              </svg>
                            </span>
                          </router-link>
                          </li>
                        </ul>
                        

                      </div>
                    </transition>
                
                  </div>
                  
                </div>
            </div>
  </nav>
 
</template>
<script>
export default {
  data () {
    return {
      scrolled: true,
    }
  },
   created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if (window.scrollY > 0){
        this.scrolled=true;
      }
      else{
        this.scrolled=false;
      }
      
    }
  }
}
</script>
<style>
</style>